<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field pubDate">
            <ul class="content">
              <li class="item select">
                <ejs-dropdownlist
                  v-model="searchOption.date"
                  :dataSource="dateCodes"
                  :allowFiltering="false"
                  :fields="dateCodesOptions"
                  cssClass="lookup-condition-dropdown"
                />
              </li>
              <li class="item dateRange">
                <input-date-range
                  v-model="searchOption.dateRange"
                  foramt="`YYYY-MM-DD`"
                />
              </li>
            </ul>
          </li>
          <li class="field memberNameNumber">
            <div class="title">쿠폰명</div>
            <ul class="content">
              <li class="item">
                <input-text
                  ref="searchCouponName"
                  v-model="q"
                  @keydown.enter="onCouponPubStatusList"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              v-on:click.native="onCouponPubStatusList"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <div class="lookup-detail">
          <erp-button
              button-div="GET"
              :is-icon-custom="true"
              v-on:click.native="onSearchDetailPopupOpen"
          >
            상세검색
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">쿠폰 목록</div>
              <div class="header-caption">[{{ gridCount }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="create">
                  <erp-button
                      button-div="GET"
                      :is-icon-custom="true"
                    v-on:click.native="createPaperCoupon"
                  >
                    지류쿠폰발행
                  </erp-button>
                </li>
                <li class="create">
                  <erp-button
                      button-div="GET"
                      :is-icon-custom="true"
                      v-on:click.native="onClickPrintConfirm"
                  >
                    인쇄 확정
                  </erp-button>
                </li>
                <li class="delete">
                  <erp-button
                      button-div="DELETE"
                      :ignore="isPopupOpened"
                      :is-shortcut-button="true"
                      v-on:click.native="onCouponPubStatusDelete"
                  >
                    삭제
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"> Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="couponPubStatusListGrid"
                width="100%"
                :provides="gridProvides"
                :columns="gridColumns"
                :isNOColumnDisplay="true"
                :allowExcelExport="true"
                :selectionSettings="selectionOptions"
                :dataSource="gridDataSource"
                :aggregates="couponPubStatusGridAggregates"
                :allowPaging="true"
                :pageSettings="pageSettings"
                @headerCellInfo="headerCellInfo"
                @queryCellInfo="queryCellInfo"
                @recordClick="recordClick"
                @actionComplete="couponPubStatusListGridActionComplete"
                @gridCheckboxChanged="gridCheckboxChanged"
                @headerSelectAllCheckboxClicked="onGridHeaderSelectAllCheckboxClicked"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <ejs-dialog
      ref="searchDetailPopup"
      :header="`상세검색`"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="false"
      :visible="false"
      width="387"
      :animationSettings="{ effect: 'None' }"
      v-show="isSearchDetailPopup"
    >
      <div class="window lookupDetail-couponPubStatus">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookupDetail">
              <ul class="lookupDetail-condition">
                <li class="field">
                  <div class="title">할인종류</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        v-model="searchDetailOption.dcKind"
                        :dataSource="dcKindCodes"
                        :fields="dateCodesOptions"
                        :allowFiltering="false"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">쿠폰유형</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        v-model="searchDetailOption.couponKind"
                        :dataSource="couponKindCodes"
                        :fields="dateCodesOptions"
                        :allowFiltering="false"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">회원명</div>
                  <ul class="content">
                    <li class="item">
                      <input-text v-model="searchDetailOption.memberName" />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">쿠폰번호</div>
                  <ul class="content">
                    <li class="item">
                      <input-text v-model="searchDetailOption.couponNo" />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">핸드폰</div>
                  <ul class="content">
                    <li class="item">
                      <input-text
                        v-model="searchDetailOption.hp4"
                        placeholder="핸드폰 뒤 4자리"
                        maxlength="4"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">SMS 수신여부</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        v-model="searchDetailOption.smsFlag"
                        :dataSource="smsSendFlagCodes"
                        :fields="dateCodesOptions"
                        :allowFiltering="false"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">타인양도여부</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        v-model="searchDetailOption.giveFlag"
                        :dataSource="giveFlagCodes"
                        :fields="dateCodesOptions"
                        :allowFiltering="false"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">만료여부</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        v-model="searchDetailOption.expirFlag"
                        :dataSource="expirFlagCodes"
                        :fields="dateCodesOptions"
                        :allowFiltering="false"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">회입여부</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        v-model="searchDetailOption.returnFlag"
                        :dataSource="returnFlagCodes"
                        :fields="dateCodesOptions"
                        :allowFiltering="false"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">내장객명</div>
                  <ul class="content">
                    <li class="item">
                      <input-text v-model="searchDetailOption.visitName" />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">비고</div>
                  <ul class="content">
                    <li class="item">
                      <input-textarea
                        v-model="searchDetailOption.remarks"
                        @keydown.native="preventEnterEvent"
                      />
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="lookup keyColor">
              <erp-button
                  button-div="GET"
                  :ignore="isPopupOpened"
                  :is-shortcut-button="true"
                  @click.native="onCouponPubStatusDetail">
                조회
              </erp-button>
            </li>
            <li class="reset">
              <erp-button
                  button-div="GET"
                  :is-icon-custom="true"
                  @click.native="onInitButtonClicked">
                초기화
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onSearchDetailPopupClose">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <coupon-pub-paper-popup
      ref="couponPubPaperPopup"
      v-if="isCouponPubPaperPopup"
      @popupClosed="couponPupPaerPopupClosed"
    />
    <coupon-pub-status-detail-popup
      ref="couponPubStatusDetailPopup"
      v-if="isCouponPubStatusDetailPopup"
      @popupClosed="couponPupStatusDetailPopupClosed"
    />
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import moment from "moment";
import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import { numberWithCommas } from "@/utils/number";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {
  Edit,
  ForeignKey,
  Resize,
  Group,
  Aggregate,
  Page,
  Filter,
  ExcelExport,
} from "@syncfusion/ej2-vue-grids";
import ejsGridWrapper from "@/components/common/EjsGridWrapper";
import CouponPubStatusDetailPopup from "@/views/member-management/CouponPubStatusDetailPopup";
import CouponPubPaperPopup from "@/views/member-management/CouponPubPaperPopup";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputTextarea from "@/components/common/text/InputTextarea";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { gridUtilGetTelColumnAccess } from "@/utils/gridUtil";
import { round as _round } from "lodash";
import ErpButton from "@/components/button/ErpButton.vue";

const searchOptionInitData = {
  date: "PUB_DATE",
  dateRange: {
    from: moment().subtract(1, "days").format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD"),
  },
};
const searchDetailOptionInitData = {
  dcKind: "%",
  couponKind: "%",
  couponNo: null,
  memberName: null,
  hp4: null,
  smsFlag: "%",
  giveFlag: "%",
  expirFlag: "%",
  returnFlag: "%",
  visitName: null,
  remarks: null,
};

export default {
  name: "CouponPubStatus",
  components: {
    InputText,
    InputTextarea,
    CouponPubPaperPopup,
    CouponPubStatusDetailPopup,
    InputDateRange,
    ejsGridWrapper,
    ErpButton,
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  computed: {
    isPopupOpened() {
      return (
        this.isSearchDetailPopup ||
        this.isCouponPubPaperPopup ||
        this.isCouponPubStatusDetailPopup
      );
    },
    dcKindCodes() {
      const tmp = commonCodesGetCommonCode("DC_KIND", true);
      tmp.unshift({ comCode: "%", comName: "전체" });
      return tmp;
    },
    couponKindCodes() {
      const tmp = commonCodesGetCommonCode("COUPON_KIND", true);
      tmp.unshift({ comCode: "%", comName: "전체" });
      return tmp;
    },
    couponPubStatusGridAggregates() {
      return [
        {
          columns: [
            {
              field: "dcKind",
              aggregationType: "TotalCaption",
              customAggregate: "합계",
            },
            {
              field: "couponKind",
              aggregationType: "TotalCount",
              stringFormat: "발행 : ${value}",
            },
            {
              field: "pubDate",
              aggregationType: "TotalCaption",
              customAggregate: () => {
                return this.$refs.couponPubStatusListGrid
                  .getGridBatchData()
                  .filter((data) => data.returnFlag).length;
              },
              stringFormat: "회입 : ${value}",
              textColor: "blue",
            },
            {
              field: "couponNo",
              aggregationType: "TotalCaption",
              customAggregate: () => {
                return this.$refs.couponPubStatusListGrid
                  .getGridBatchData()
                  .filter((data) => !data.returnFlag && data.expirFlag).length;
              },
              stringFormat: "만료 : ${value}",
              textColor: "red",
            },
            {
              field: "memberName",
              aggregationType: "TotalCaption",
              customAggregate: () => {
                const gridFilteredData = this.$refs.couponPubStatusListGrid.getGridBatchData();
                const remainCount = gridFilteredData.filter(
                  (data) => !data.returnFlag && !data.expirFlag
                ).length;
                return remainCount;
              },
              stringFormat: "잔여 : ${value}",
            },
            {
              field: "memberDiv",
              aggregationType: "TotalCaption",
              customAggregate: () => {
                const gridFilteredData = this.$refs.couponPubStatusListGrid.getGridBatchData();
                const returnCount = gridFilteredData.filter(
                  (data) => data.returnFlag
                ).length;
                return _round((returnCount / gridFilteredData.length) * 100, 2);
              },
              stringFormat: "회수율 : ${value}%",
            },
          ],
        },
      ];
    },
  },
  mounted() {
    // 기본 포커싱
    this.$refs.searchCouponName.focusIn();
  },
  data() {
    return {
      toExcludeIndexList: [],
      q: "",
      gridCount: 0,
      gridProvides: [
        Edit,
        Aggregate,
        ForeignKey,
        Resize,
        Group,
        Page,
        Filter,
        ExcelExport,
      ],
      selectionOptions: { type: "Single", mode: "Both", enableToggle: true },
      isSearchDetailPopup: false,
      isCouponPubStatusDetailPopup: false,
      isCouponPubPaperPopup: false,
      gridDataSource: [],
      dateCodesOptions: { text: "comName", value: "comCode" },
      dateCodes: [
        { comCode: "PUB_DATE", comName: "발행일자" },
        { comCode: "RETURN_DATE", comName: "사용일자" },
      ],
      pageSettings: { pageSize: 50 },
      searchOption: JSON.parse(JSON.stringify(searchOptionInitData)),
      searchDetailOption: JSON.parse(
        JSON.stringify(searchDetailOptionInitData)
      ),
      smsSendFlagCodes: [
        { comCode: "%", comName: "전체" },
        { comCode: "1", comName: "수신" },
        { comCode: "0", comName: "미수신" },
      ],
      giveFlagCodes: [
        { comCode: "%", comName: "전체" },
        { comCode: "1", comName: "가능" },
        { comCode: "0", comName: "불가능" },
      ],
      expirFlagCodes: [
        { comCode: "%", comName: "전체" },
        { comCode: "1", comName: "사용만료" },
        { comCode: "0", comName: "사용가능" },
      ],
      returnFlagCodes: [
        { comCode: "%", comName: "전체" },
        { comCode: "1", comName: "회입" },
        { comCode: "0", comName: "미회입" },
      ],
      gridColumns: [
        {
          isPrimaryKey: true,
          visible: false,
          field: "couponId",
        },
        {
          field: "kindId",
          headerText: "구분",
          visible: false,
        },
        {
          headerText: "쿠폰정보",
          columns: [
            {
              field: "select",
              headerText: "",
              type: "boolean",
              editType: "booleanedit",
              displayAsCheckBox: true,
              width: 50,
              textAlign: "center",
              allowFiltering: false,
              allowSorting: false,
              isSelectAllColumn: true,
            },
            {
              headerText: "할인종류",
              field: "dcKind",
              groupCode: "DC_KIND",
              isCommonCodeField: true,
              textAlign: "Center",
              type: "string",
              width: "120",
              allowEditing: false,
            },
            {
              headerText: "쿠폰명",
              field: "couponName",
              textAlign: "Center",
              width: "120",
              type: "string",
              allowEditing: false,
            },
            {
              headerText: "쿠폰종류",
              field: "couponKind",
              groupCode: "COUPON_KIND",
              isCommonCodeField: true,
              textAlign: "Center",
              type: "string",
              width: "100",
              allowEditing: false,
            },
            {
              headerText: "발행일자",
              field: "pubDate",
              textAlign: "Center",
              width: "100",
              type: "string",
              allowEditing: false,
            },
            {
              headerText: "쿠폰번호",
              field: "couponNo",
              textAlign: "Center",
              width: "100",
              type: "string",
              allowEditing: false,
            },
            {
              headerText: "양도",
              field: "giveFlag",
              type: "boolean",
              editType: "booleanedit",
              textAlign: "Center",
              width: "70",
              displayAsCheckBox: true,
              allowEditing: false,
            },
            {
              headerText: "인쇄여부",
              field: "printFlag",
              type: "boolean",
              editType: "booleanedit",
              textAlign: "Center",
              width: "100",
              displayAsCheckBox: true,
              allowEditing: false,
            },
          ],
        },
        {
          headerText: "발행정보",
          columns: [
            {
              headerText: "회원명",
              field: "memberName",
              textAlign: "Center",
              width: "90",
              type: "string",
              allowEditing: false,
            },
            {
              headerText: "회원구분",
              field: "memberDiv",
              groupCode: "MEMBER_DIV",
              isCommonCodeField: true,
              textAlign: "Center",
              type: "string",
              width: "120",
              allowEditing: false,
            },
            {
              headerText: "핸드폰",
              field: "contactTel",
              textAlign: "Center",
              width: "110",
              valueAccessor: gridUtilGetTelColumnAccess,
              type: "string",
              allowEditing: false,
            },
            {
              headerText: "SMS",
              field: "smsSendFlag",
              type: "boolean",
              editType: "booleanedit",
              textAlign: "Center",
              width: "80",
              displayAsCheckBox: true,
              allowEditing: false,
            },
            {
              headerText: "시작일자",
              field: "startDate",
              textAlign: "Center",
              width: "100",
              type: "string",
              allowEditing: false,
            },
            {
              headerText: "만료일자",
              field: "expirDate",
              textAlign: "Center",
              width: "100",
              type: "string",
              allowEditing: false,
            },
          ],
        },
        {
          headerText: "할인방법",
          columns: [
            {
              headerText: "할인방법",
              field: "dcMethod",
              groupCode: "DC_METHOD",
              isCommonCodeField: true,
              textAlign: "Center",
              type: "string",
              width: "100",
              allowEditing: false,
            },
            {
              isNumericType: true,
              field: "dcAmt",
              headerText: "할인금액(율)",
              textAlign: "right",
              type: "string",
              width: 120,
              allowEditing: false,
            },
            {
              headerText: "요금코드",
              field: "paymtCode",
              groupCode: "PAYMT_CODE",
              isCommonCodeField: true,
              textAlign: "Center",
              type: "string",
              width: "100",
              allowEditing: false,
            },
          ],
        },
        {
          headerText: "사용제한",
          columns: [
            {
              headerText: "매장",
              field: "storeCode",
              groupCode: "STORE_CODE",
              isCommonCodeField: true,
              textAlign: "Center",
              type: "string",
              width: "100",
              allowEditing: false,
            },
            {
              headerText: "영업구분",
              field: "bsnCode",
              groupCode: "BSN_CODE",
              isCommonCodeField: true,
              textAlign: "Center",
              type: "string",
              width: "100",
              allowEditing: false,
            },
            {
              headerText: "시간",
              textAlign: "Center",
              valueAccessor: this.timeValueAccessor,
              width: "120",
              type: "string",
              allowEditing: false,
            },
            {
              headerText: "중복",
              field: "dplctDcFlag",
              type: "boolean",
              editType: "booleanedit",
              textAlign: "Center",
              width: "70",
              displayAsCheckBox: true,
              allowEditing: false,
            },
          ],
        },
        {
          headerText: "회입정보",
          columns: [
            {
              headerText: "회입여부",
              field: "returnFlag",
              type: "boolean",
              editType: "booleanedit",
              textAlign: "Center",
              width: "90",
              displayAsCheckBox: true,
              allowEditing: false,
            },
            {
              headerText: "회입매장",
              field: "returnStore",
              groupCode: "STORE_CODE",
              isCommonCodeField: true,
              textAlign: "Center",
              type: "string",
              width: "100",allowEditing: false,
            },
            {
              headerText: "회입일자",
              field: "returnDate",
              textAlign: "Center",
              width: "100",
              type: "string",
              allowEditing: false,
            },
            {
              headerText: "내장객명",
              field: "visitName",
              textAlign: "Center",
              width: "90",
              type: "string",
              allowEditing: false,
            },
            {
              headerText: "시간",
              field: "startTime",
              textAlign: "Center",
              width: "90",
              type: "string",
              allowEditing: false,
            },
            {
              groupCode: "COURSE_CODE",
              isCommonCodeField: true,
              field: "startCourse",
              headerText: "코스",
              minWidth: 16,
              textAlign: "Center",
              width: 90,
              type: "string",
              allowEditing: false,
            },
          ],
        },
        {
          headerText: "비고",
          field: "remarks",
          textAlign: "Center",
          width: "130",
          type: "string",
          allowEditing: false,
        },
        {
          headerText: "등록자",
          field: "userName",
          textAlign: "Center",
          width: "80",
          type: "string",
          allowEditing: false,
        },
        {
          headerText: "등록일시",
          field: "insertDt",
          textAlign: "Center",
          width: "140",
          valueAccessor: this.timeStampValueAccessor,
          type: "string",
          allowEditing: false,
        },
      ],
    };
  },
  methods: {
    numberWithCommas,
    gridUtilGetTelColumnAccess,
    timeStampValueAccessor(field, data) {
      return moment(data.insertDt).format("YYYY-MM-DD HH:mm:ss");
    },
    timeValueAccessor(field, data) {
      return data.useStartTime + "~" + data.useEndTime;
    },
    couponPupStatusDetailPopupClosed(isRefresh) {
      this.isCouponPubStatusDetailPopup = false;
      if (isRefresh) {
        this.onCouponPubStatusList();
      }
    },
    couponPupPaerPopupClosed(isRefresh) {
      this.isCouponPubPaperPopup = false;
      if (isRefresh) {
        this.onCouponPubStatusList();
      }
    },
    recordClick(args) {
      if (
        args.column &&
        (args.column.field === "couponName" || args.column.field === "couponNo")
      ) {
        this.isCouponPubStatusDetailPopup = true;
        this.$nextTick(() => {
          this.$refs.couponPubStatusDetailPopup.showPopup(
            args.rowData.couponId
          );
        });
      }
    },
    headerCellInfo(args) {
      if (
        args.cell.column.field === "couponName" ||
        args.cell.column.field === "couponNo"
      ) {
        args.node.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    queryCellInfo(args) {
      if (
        args.column.field === "couponName" ||
        args.column.field === "couponNo"
      ) {
        args.cell.classList.add(this.$t("className.grid.clickArea"));
      }
      if (
        args.column &&
        args.column.field === "couponNo" &&
        moment(args.data.expirDate, "YYYY-MM-DD").add("day", 1) < moment()
      ) {
        args.cell.style.textDecoration = "line-through";
        args.cell.style.textDecorationColor = "red";
      }
      if (
        args.column &&
        args.column.field === "couponNo" &&
        args.data.returnFlag
      ) {
        args.cell.style.textDecoration = "none";
        args.cell.style.color = "blue";
      }
      if (args.column.field === "dcAmt") {
        args.cell.innerText = `${this.numberWithCommas(args.data?.dcAmt)}${
          args.data?.dcMethod === "RATE" ? "%" : ""
        }`;
      }
      if (args.column.field === "select") {
        args.cell.classList.add(this.$t("className.grid.modifyArea"));
      }
    },
    async gridCheckboxChanged(args) {
      const {
        columnName: field,
        rowData,
      } = args;

      if (field === "select") {
        const hasRIdx = this.toExcludeIndexList
            ?.findIndex(item => item === rowData._rid);
        const findIndex = this.gridDataSource
            ?.findIndex(item => item._rid === rowData._rid);

        if (findIndex > -1) {
          if (hasRIdx > -1) {
            this.gridDataSource[findIndex].select = false;
            delete this.toExcludeIndexList[hasRIdx];
            this.toExcludeIndexList = this.toExcludeIndexList
                ?.filter(item => !!item);
          } else {
            this.gridDataSource[findIndex].select = true;
            this.toExcludeIndexList.push(rowData._rid);
          }
          this.$forceUpdate();
        }
      }
    },
    onGridHeaderSelectAllCheckboxClicked(args) {
      const {
        field,
        value,
      } = args;

      if (field === "select") {
        this.toExcludeIndexList = [];
        this.gridDataSource.map(item => item.select = false);

        if (value) {
          const filteredRecords = this.$refs.couponPubStatusListGrid.getFilteredRecords();
          if (filteredRecords.length > 0) {
            filteredRecords.forEach(record => {
              record.select = value;
              this.toExcludeIndexList.push(record._rid);
            });
          } else {
            this.gridDataSource.map(item => {
              item.select = value;
              this.toExcludeIndexList.push(item._rid);
            });
          }
        }
      }
    },
    async onCouponPubStatusList() {
      this.fetchCouponPubStatusList(this.searchOption, this.searchDetailOption);
    },

    fetchCouponPubStatusList(args, detailArgs) {
      if (!args.dateRange.from) {
        const columnName = this.dateCodes.find(
          (item) => item.comCode === args.date
        );

        return this.errorToast(
          this.$t("main.validationMessage.requiredMessage", [
            columnName.comName,
          ])
        );
      }
      if (!args.dateRange.to) {
        return this.errorToast(
          this.$t("main.validationMessage.requiredMessage", ["종료일자"])
        );
      }

      let body = {
        dateFrom: args.dateRange.from,
        dateTo: args.dateRange.to,
        dateType: args.date,
        couponName: this.q || undefined,
      };
      if (detailArgs) {
        let tmpDetail = JSON.parse(JSON.stringify(detailArgs));
        tmpDetail.smsFlag =
          tmpDetail.smsFlag === "%" ? null : tmpDetail.smsFlag;
        tmpDetail.giveFlag =
          tmpDetail.giveFlag === "%" ? null : tmpDetail.giveFlag;
        tmpDetail.expirFlag =
          tmpDetail.expirFlag === "%" ? null : tmpDetail.expirFlag;
        tmpDetail.returnFlag =
          tmpDetail.returnFlag === "%" ? null : tmpDetail.returnFlag;
        body = { ...body, ...tmpDetail };
      }
      
      this.toExcludeIndexList = [];
      GolfErpAPI.fetchCouponPubStatusList(body).then((res) => {
        this.gridDataSource = res.map((item, idx) => ({
          ...item,
          _rid: idx + 1,
          select: false,
        }));
      });
    },
    createPaperCoupon() {
      this.isCouponPubPaperPopup = true;
      this.$nextTick(() => {
        this.$refs.couponPubPaperPopup.showPopup();
      });
    },
    async onClickPrintConfirm() {
      const selectCouponData = this.gridDataSource.filter(item => this.toExcludeIndexList.includes(item._rid));
      if (selectCouponData && selectCouponData.length > 0) {
        if (await this.confirm("정말 인쇄 확정하시겠습니까?")) {
          const selectCouponId = selectCouponData.map((data) => data.couponId);

          GolfErpAPI.couponPubPrintConfirm(selectCouponId).then(
              () => {
                this.infoToast("인쇄 확정하였습니다.");
                this.onCouponPubStatusList();
              }
          );
        }

      } else {
        this.errorToast("선택 된 쿠폰이 없습니다.");
      }
    },
    preventEnterEvent(event) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    },
    onSearchDetailPopupOpen() {
      this.isSearchDetailPopup = true;
      this.$refs.searchDetailPopup.show();
    },
    onSearchDetailPopupClose() {
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopup = false;
    },
    onInitButtonClicked() {
      this.searchDetailOption = JSON.parse(
        JSON.stringify(searchDetailOptionInitData)
      );
    },
    onCouponPubStatusDetail() {
      this.fetchCouponPubStatusList(this.searchOption, this.searchDetailOption);
    },
    async onCouponPubStatusDelete() {
      const selectCouponData = this.$refs.couponPubStatusListGrid.getSelectedRecords();
      if (selectCouponData && selectCouponData.length > 0) {
        if (await this.confirm("정말 삭제하시겠습니까?")) {
          const selectCouponId = selectCouponData.map((data) => data.couponId);
          let returnDatas = [];
          selectCouponData.forEach((data) => {
            if (data.returnFlag) {
              returnDatas.push(data);
            }
          });

          if (returnDatas.length > 0) {
            if (
              !(await this.confirm(
                "회입 처리된 쿠폰이 있습니다.<br>정말 삭제하시겠습니까?"
              ))
            ) {
              return;
            }
          }

          GolfErpAPI.deleteCouponPubStatus({ data: selectCouponId }).then(
            () => {
              this.infoToast(this.$t("main.popupMessage.deleted"));
              this.onCouponPubStatusList();
            }
          );
        }
      } else {
        this.errorToast("삭제할 쿠폰 정보가 없습니다");
      }
    },
    couponPubStatusListGridActionComplete() {
      this.gridCount = numberWithCommas(
        this.$refs.couponPubStatusListGrid?.getGridBatchCount() || 0
      );
    },
    async onClickExcel() {
      this.$refs.couponPubStatusListGrid.excelExport();
    },
  },
};
</script>

<style>
body .appContent .body-article .section-body {overflow: hidden;border: none;}
</style>
