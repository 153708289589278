<template>
  <div>
    <ejs-dialog
      ref="couponPubPaperPopupDialog"
      header="지류쿠폰발행"
      :allowDragging="true"
      :showCloseIcon="true"
      width="400"
      :close="dialogClose"
      :position="{ x: 'center' }"
      :enableResize="false"
      :isModal="true"
    >
      <div class="window couponPubPaperPopup">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">쿠폰선택</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">쿠폰종류</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group select">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="couponKind"
                                        v-model="selectedKindId"
                                        :dataSource="couponKindNameList"
                                        :allowFiltering="false"
                                        :fields="dropdownCouponKindCodesOptions"
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">할인종류</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item text">
                                    {{
                                      getDcKindName(
                                        selectedCouponPaperInfo.dcKind
                                      )
                                    }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-02">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">할인 방법</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <div class="title required">할인방법</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group select">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="dcMethod"
                                        v-model="
                                          selectedCouponPaperInfo.dcMethod
                                        "
                                        :dataSource="dcMethodCodes"
                                        :allowFiltering="false"
                                        :fields="dropdownListCodesOptions"
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div
                                :class="[
                                  'title',
                                  {
                                    required:
                                      selectedCouponPaperInfo.dcMethod !==
                                      'PAYMT',
                                  },
                                ]"
                              >
                                금액/할인율
                              </div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-number
                                        ref="dcAmt"
                                        v-model="selectedCouponPaperInfo.dcAmt"
                                        :disabled="
                                          selectedCouponPaperInfo.dcMethod ===
                                          'PAYMT'
                                        "
                                        :allow-dot="
                                          selectedCouponPaperInfo.dcMethod ===
                                          'RATE'
                                        "
                                        :propMaxLength="
                                          selectedCouponPaperInfo.dcMethod ===
                                          'AMT'
                                            ? 13
                                            : 3
                                        "
                                        :max="
                                          selectedCouponPaperInfo.dcMethod ===
                                          'RATE'
                                            ? 100
                                            : undefined
                                        "
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div
                                :class="[
                                  'title',
                                  {
                                    required:
                                      selectedCouponPaperInfo.dcMethod ===
                                      'PAYMT',
                                  },
                                ]"
                              >
                                요금코드
                              </div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group select">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="paymtCode"
                                        v-model="
                                          selectedCouponPaperInfo.paymtCode
                                        "
                                        :enabled="
                                          selectedCouponPaperInfo.dcMethod ===
                                          'PAYMT'
                                        "
                                        :dataSource="paymtCodes"
                                        :allowFiltering="false"
                                        :fields="dropdownListCodesOptions"
                                        cssClass="body-data-dropdown"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-03">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">사용제한</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <div class="title required">사용매장</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group select">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="storeCode"
                                        v-model="
                                          selectedCouponPaperInfo.storeCode
                                        "
                                        :dataSource="storeCodes"
                                        :allowFiltering="false"
                                        :fields="dropdownListCodesOptions"
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title required">영업구분</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group select">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="bsnCode"
                                        v-model="
                                          selectedCouponPaperInfo.bsnCode
                                        "
                                        :dataSource="bsnCodes"
                                        :allowFiltering="false"
                                        :fields="dropdownListCodesOptions"
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title required">사용가능시간</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group time">
                                    <div class="form">
                                      <input-time
                                        v-model="
                                          selectedCouponPaperInfo.useStartTime
                                        "
                                        :format="'HH:mm'"
                                      />
                                    </div>
                                  </li>
                                  <li class="item text">~</li>
                                  <li class="item form-group time">
                                    <div class="form">
                                      <input-time
                                        v-model="
                                          selectedCouponPaperInfo.useEndTime
                                        "
                                        :format="'HH:mm'"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">중복할인가능</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group check">
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                            type="checkbox"
                                            v-model="
                                              selectedCouponPaperInfo.dplctDcFlag
                                            "
                                          />
                                          <i></i>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-04">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">발행 정보</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field" v-show="isCouponByTicket">
                              <div
                                class="title"
                                :class="{
                                  required: isCouponByTicket,
                                }"
                              >
                                회사명
                              </div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group select" style="width: 40%;">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        v-model="
                                          selectedCouponPaperInfo.companyCodeAttrb
                                        "
                                        :dataSource="companyCodeAttrbGroups"
                                        :allowFiltering="false"
                                        :fields="dropdownListCodesOptions"
                                        cssClass="body-data-dropdown"
                                        @change="onCompanyCodeAttrbChange"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group select" style="width: 60%;">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="companyCode"
                                        v-model="
                                          selectedCouponPaperInfo.companyCode
                                        "
                                        :dataSource="companyCodes"
                                        :allowFiltering="false"
                                        :fields="dropdownListCodesOptions"
                                        cssClass="body-data-dropdown"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field" v-show="isCouponByTicket">
                              <div
                                class="title"
                                :class="{
                                  required: isCouponByTicket,
                                }"
                              >
                                발행년도
                              </div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-number
                                        ref="year"
                                        v-model="selectedCouponPaperInfo.year"
                                        :allowDot="false"
                                        :allowMinus="false"
                                        :displayComma="false"
                                        :propMaxLength="4"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">쿠폰시작번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li v-show="isCouponByTicket" class="item form-group" style="width: 40%">
                                    {{ couponFirstTextByTicket }}
                                  </li>
                                  <li
                                    class="item form-group"
                                    :style="{
                                      width: `${isCouponByTicket ? '60%' : '100%'}`
                                    }"
                                  >
                                    <div class="form">
                                      <input-number
                                          v-model="couponStartNo"
                                          :display-comma="false"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">기 발행매수</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item text right">
                                    {{
                                      numberWithCommas(
                                        selectedCouponPaperInfo.pubCount
                                      )
                                    }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">발행제한매수</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item text right">
                                    {{
                                      numberWithCommas(
                                        selectedCouponPaperInfo.pubLmttNmrs
                                      )
                                    }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">발행매수</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-number
                                        v-model="pubSize"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">발행일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-date
                                        v-model="pubDate"
                                        format="YYYY-MM-DD"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">유효기간</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-date-range
                                        v-model="expirDateRange"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="create keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-icon-custom="true"
                  :is-shortcut-button="true"
                v-on:click.native="onClickCouponPubPager"
              >
                쿠폰생성
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  v-on:click.native="dialogClose"> 닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import { commonCodesGetCommonCode, commonCodesGetCommonCodeByIdx, commonCodesGetCommonCodeByIdxAttrb } from "@/utils/commonCodes";
import { validateFormRefs } from "@/utils/formUtil";
import { numberWithCommas } from "@/utils/number";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import commonMixin from "@/views/layout/mixin/commonMixin";
import Vue from "vue";
import AsyncComputed from "vue-async-computed";
import GolfERPService from "@/service/GolfERPService";
import moment from "moment";
import inputNumber from "@/components/common/InputNumber";
import inputTime from "@/components/common/datetime/InputTime";
import InputDate from "@/components/common/datetime/InputDate";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton.vue";
import groupBy from "lodash/groupBy";

Vue.use(AsyncComputed);

const couponInfoInitData = Object.freeze({
  dcKind: null,
  giveFlag: true,
  dcMethod: null,
  dcAmt: null,
  paymtCode: null,
  storeCode: null,
  bsnCode: "%",
  useStartTime: null,
  useEndTime: null,
  dplctDcFlag: true,
  pubLmttNmrs: null,
  pubCount: null,
  memberName: null,
  companyCodeAttrb: null,
  companyCode: null,
  year: null,
});

export default {
  name: "CouponPubPaperPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    InputDateRange,
    InputDate,
    inputNumber,
    inputTime,
    ErpButton,
  },
  data() {
    return {
      dcKindCodes: commonCodesGetCommonCode("DC_KIND"),
      dcMethodCodes: commonCodesGetCommonCode("DC_METHOD"),
      couponKindCodes: commonCodesGetCommonCode("COUPON_KIND"),
      memberDivCodes: commonCodesGetCommonCode("MEMBER_DIV"),
      paymtCodes: commonCodesGetCommonCode("PAYMT_CODE"),
      startCourseCodes: commonCodesGetCommonCode("START_COURSE"),
      dropdownListCodesOptions: { text: "comName", value: "comCode" },
      dropdownCouponKindCodesOptions: { text: "couponName", value: "kindId" },
      selectedCouponPaperInfo: JSON.parse(JSON.stringify(couponInfoInitData)),
      selectedKindId: null,
      oldCouponInfo: null,
      couponKindList: [],
      couponKindNameList: [],
      pubSize: 0,
      maxPubSize: 0,
      pubDate: moment().format("YYYY-MM-DD"),
      couponStartNo: null,
      expirDateRange: {
        from: moment().format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
      },
      couponFirstText: null,
    };
  },
  watch: {
    couponFirstTextByTicket(val) {
      if (this.selectedKindId) {
        const couponKind = this.couponKindList.find(item => item.kindId === this.selectedKindId);
        if (couponKind?.couponName === "이용권") {
          if (this.couponFirstText !== this.couponFirstTextByTicket) {
            GolfErpAPI.fetchTicketPubInfo(this.couponFirstTextByTicket)
              .then((data) => {
                const { maxCouponNo, pubCount } = data;
                this.couponStartNo = (maxCouponNo || 0) + 1;
                this.selectedCouponPaperInfo.pubCount = pubCount || 0;
                this.couponFirstText = JSON.parse(JSON.stringify(this.couponFirstTextByTicket));
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      }
    },
    selectedKindId(val) {
      if (val) {
        this.selectedCouponPaperInfo = this.couponKindList.find(
          (data) => data.kindId === val
        );
        if (this.selectedCouponPaperInfo.couponMaxNo != null) {
          let couponStartNo = Number.parseInt(
            this.selectedCouponPaperInfo.couponMaxNo
          );
          if (!isNaN(couponStartNo)) {
            couponStartNo++;
          } else {
            couponStartNo = this.selectedCouponPaperInfo.couponStartNo;
          }
          this.couponStartNo = couponStartNo;
        } else {
          this.couponStartNo = this.selectedCouponPaperInfo.couponStartNo;
        }
        this.pubSize =
          this.selectedCouponPaperInfo.pubLmttNmrs -
          this.selectedCouponPaperInfo.pubCount;
        this.maxPubSize =
          this.selectedCouponPaperInfo.pubLmttNmrs -
          this.selectedCouponPaperInfo.pubCount;
        if (this.maxPubSize > 10000) {
          this.pubSize = 10000;
          this.maxPubSize = 10000;
        }
        if (this.pubSize < 0) {
          this.pubSize = 0;
          this.maxPubSize = 0;
        }
        this.expirDateRange.from = moment().format("YYYY-MM-DD");
        if (this.selectedCouponPaperInfo.expirMonthCnt) {
          this.expirDateRange.to = moment()
            .add(this.selectedCouponPaperInfo.expirMonthCnt, "M")
            .subtract(1, "days")
            .format("YYYY-MM-DD");
        } else {
          this.expirDateRange.to = moment().format("YYYY-MM-DD");
        }
        this.oldCouponInfo = JSON.parse(
          JSON.stringify(this.selectedCouponPaperInfo)
        );
      }
    },
    selectedCouponPaperInfo: {
      deep: true,
      handler(data) {
        if (data.dcMethod === "PAYMT") {
          data.dcAmt = 0;
        } else {
          data.paymtCode = null;
        }
        if (this.oldCouponInfo) {
          if (this.oldCouponInfo.dcMethod !== data.dcMethod) {
            data.dcAmt = 0;
          }
        }
        this.oldCouponInfo = JSON.parse(JSON.stringify(data));
      },
    },
  },
  asyncComputed: {
    async storeCodes() {
      const response = await GolfERPService.fetchStores(true);
      return commonCodesGetCommonCode("STORE_CODE").filter((data) => {
        return response.find((rData) => rData.code === data.comCode);
      });
    },
  },
  computed: {
    couponFirstTextByTicket() {
      const year = this.selectedCouponPaperInfo.year;
      const companyAttrb = this.selectedCouponPaperInfo.companyCodeAttrb || "00";
      const bsnCode = this.selectedCouponPaperInfo.bsnCode === "WEEKEND" ? "02" : "01";
      return `${year}-${bsnCode}-${companyAttrb}-`;
    },
    companyCodes() {
      return commonCodesGetCommonCodeByIdxAttrb("COMPANY_CODE", 1, this.selectedCouponPaperInfo.companyCodeAttrb);
    },
    companyCodeAttrbGroups() {
      const companyCodes =
        groupBy(commonCodesGetCommonCodeByIdx("COMPANY_CODE", 1)
          .map(item => item.comCodeAttrbList)
          .flat()
        , "attrb")
      ;
      return Object.keys(companyCodes).map(key => {
        return {
          comCode: key,
          comName: companyCodes[key]?.find(item => item.attrb === key)?.attrbName || ""
        };
      });
    },
    bsnCodes() {
      const result = commonCodesGetCommonCode("BSN_CODE").filter(
        (data) => data.comCode !== "CLOSE"
      );
      if (!this.isCouponByTicket) {
        result.unshift({comCode: "%", comName: "전체"});
      }
      return result;
    },
    isCouponByTicket() {
      return this.couponKindNameList.findIndex(item => item.kindId === this.selectedKindId && item.couponName === "이용권") >= 0;
    },
    validateRefList() {
      return {
        bsnCode: { required: true },
        couponKind: { required: true },
        companyCode: { required: this.isCouponByTicket },
        year: {
          required: this.isCouponByTicket,
          min: this.isCouponByTicket ? 2000 : 0,
          custom: {
            method: (value) => {
              if (!this.isCouponByTicket || value !== 0) {
                return true;
              }
            },
            message: "main.validationMessage.requiredMessage",
          },
        },
      };
    },
  },
  methods: {
    validateFormRefs,
    numberWithCommas,
    getCouponKindName(data) {
      if (!data) {
        return null;
      }
      return this.couponKindCodes.find((dcData) => dcData.comCode === data)
        .comName;
    },
    getTimeFormat(data) {
      if (!data) {
        return null;
      }
      return moment(data).format("YYYY-MM-DD");
    },
    getDcKindName(data) {
      if (!data) {
        return null;
      }
      return this.dcKindCodes.find((dcData) => dcData.comCode === data).comName;
    },
    initCouponPubStatusDetailData() {
      this.selectedCouponPaperInfo = JSON.parse(
        JSON.stringify(couponInfoInitData)
      );

      GolfErpAPI.fetchCouponPubPaperInfo().then((data) => {
        this.couponKindList = data.map(item => ({
          ...item,
          year: Number(moment().add(1, "years").format("YYYY")),
          companyCode: null,
          companyCodeAttrb: null,
        }));
        this.couponKindNameList = data.map((data) => {
          return { kindId: data.kindId, couponName: data.couponName };
        });
      });
    },
    showPopup() {
      this.initCouponPubStatusDetailData();
      this.$refs.couponPubPaperPopupDialog?.show();
    },
    dialogClose(isRefresh) {
      if (isRefresh === true) {
        this.$emit("popupClosed", true);
      } else {
        this.$emit("popupClosed", null);
      }
    },
    async onClickCouponPubPager() {
      if (!this.validateFormRefs(this.validateRefList)) {
        return;
      }

      // 쿠폰 발행시 10,000매 이상 발행시 API의 Time Out 오류 발생.
      if (this.pubSize <= 10000) {
        if (this.maxPubSize < this.pubSize) {
          if (
              !(await this.confirm(
                  "쿠폰의 발행가능 매수는 " +
                  this.maxPubSize +
                  "매 입니다. 초과 발행 하시겠습니까?"
              ))
          ) {
            this.pubSize = JSON.parse(JSON.stringify(this.maxPubSize));
            return;
          }
        }
      } else {
        this.errorToast("최대 발행 가능 매수는 10,000매 입니다.");
        this.pubSize = JSON.parse(JSON.stringify(this.maxPubSize));
        return;
      }

      const pubBody = JSON.parse(JSON.stringify(this.selectedCouponPaperInfo));
      pubBody.couponStartNo = this.couponStartNo;
      pubBody.pubSize = this.pubSize;
      pubBody.startDate = this.expirDateRange.from;
      pubBody.expirDate = this.expirDateRange.to;
      pubBody.pubDate = this.pubDate;
      pubBody.companyCode = this.isCouponByTicket ? pubBody.companyCode : null;
      pubBody.couponStartStr = this.isCouponByTicket ? this.couponFirstTextByTicket : null;

      GolfErpAPI.postCouponPublishPaper(pubBody)
        .then(() => {
          this.infoToast("쿠폰을 발행하였습니다");
          this.dialogClose(true);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onCompanyCodeAttrbChange() {
      this.selectedCouponPaperInfo.companyCode = null;
    },
  },
};
</script>
