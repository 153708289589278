<template>
  <div id="popupWrapper">
    <ejs-dialog
      ref="batchSmsCouponSendPopup"
      header="쿠폰 SMS 전송"
      allowDragging="true"
      showCloseIcon="true"
      isModal="true"
      width="320"
      height="740"
      :enableResize="true"
      :animationSettings="{ effect: 'None' }"
      :visible="isVisible"
      @close="onBatchSmsSendPopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <section
                  class="article-section section-01"
                  style="height: calc(100% - 43px)"
                >
                  <div class="sms-send-box">
                    <div class="preview-top">
                    <div class="speaker-box" />
                    <div class="speaker-small-box" />
                    </div>
                    <div class="preview-middle">
                      <div class="screen-box">
                        <div class="user-profile">
                          <div class="image">{{ username.substr(0, 1) }}</div>
                          <div class="name">{{ username }}</div>
                        </div>
                        <div class="message-box">
                          [Web발신]
                          <br />
                          <div
                            class="preview"
                            v-if="
                              smsSendInfo.bizNameLocation === 'UP' &&
                              smsSendInfo.bizName
                            "
                          >
                            {{ smsSendInfo.bizName }}
                            <br />
                            <br />
                          </div>
                          <div
                            class="preview"
                            v-html="smsSendInfo.message.replace(/\n/g, '<br>')"
                            v-if="smsSendInfo.message"
                          />
                          <br
                            v-if="
                              smsSendInfo.message && smsSendInfo.aditWordsFlag
                            "
                          />
                          <textarea
                            ref="aditWords"
                            placeholder="메시지를 입력해주세요"
                            v-model="aditWords"
                            @keyup="resize"
                            @keydown="resize"
                            @focus="resize"
                            spellcheck="false"
                            autofocus
                          />
                          <div
                            class="preview"
                            v-if="
                              smsSendInfo.bizNameLocation === 'DOWN' &&
                              smsSendInfo.bizName
                            "
                          >
                            <br />
                            {{ smsSendInfo.bizName }}
                          </div>
                          <div class="imagePreview" v-if="popupData.imagePath">
                            <img :src="popupData.imagePath" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="preview-bottom">
                      <div class="button-box" />
                    </div>
                  </div>
                </section>
                <section
                  class="article-section section-02"
                  style="height: 43px"
                >
                  <div class="section-body" style="border: none; overflow: hidden !important;">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field" style="width: calc(100% + 1px)">
                              <!-- 필수 : required -->
                              <div class="title">발신번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        ref="dsptchNo"
                                        v-model="smsSendInfo.dsptchNo"
                                        disabled
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="keyColor">
              <erp-button
                button-div="SAVE"
                :is-custom-shortcut-button="true"
                :shortcut="sendSmsButtonProps.shortcut"
                :shortcut-key="sendSmsButtonProps.shortcutKey"
                :ignore="showTestSmsSendPopup"
                @click.native="onSmsSendButtonClicked"
              >
                전송
              </erp-button>
            </li>
            <li>
              <erp-button button-div="SAVE" @click.native="onTestButtonClicked">
                검증
              </erp-button>
            </li>
            <li class="reset">
              <erp-button button-div="GET" @click.native="onInitButtonClicked">
                초기화
              </erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="closeBatchSmsSendPopup">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <test-sms-send-popup
      v-if="showTestSmsSendPopup"
      ref="testSmsSendPopup"
      @smsSend="smsSend"
      @popupClosed="onTestSmsSendPopup"
    />
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";

import { commonCodesGetStandardInfo } from "@/utils/commonCodes";
import { getDayOfWeekCaption, getDayOfWeekCaptionColor } from "@/utils/date";
import { getFormattedTelNumber } from "@/utils/string";
import { validateFormRefs } from "@/utils/formUtil";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { mapGetters } from "vuex";
import TestSmsSendPopup from "@/components/popups/TestSmsSendPopup";
import { SHORTCUT_KEYS } from "@/utils/KeyboardUtil";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "batchSmsCouponSendPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: { TestSmsSendPopup, InputText, ErpButton },
  data() {
    return {
      isVisible: false,
      showTestSmsSendPopup: false,
      smsSendInfo: {},
      popupData: {},
      aditWords: "",
    };
  },
  computed: {
    ...mapGetters(["username"]),
    sendSmsButtonProps() {
      return {
        shortcutKey: "BatchSmsCouponSendPopup.shortcuts.sendSms",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F7,
        },
      };
    },
  },
  methods: {
    validateFormRefs,
    getDayOfWeekCaption,
    getDayOfWeekCaptionColor,
    async showBatchSmsSendPopup(popupData) {
      this.popupData = popupData;

      await this.fetchSmsWords();
    },
    onBatchSmsSendPopupClosed() {
      this.$emit("popupClosed");
    },
    closeBatchSmsSendPopup() {
      this.isVisible = false;
      this.$refs.batchSmsCouponSendPopup.hide();
    },
    async fetchSmsWords() {
      this.smsWords = await GolfErpAPI.fetchSmsWords();
      const smsSendType = this.smsWords.find(
        (data) => data.smsSendType === "COUPON"
      );
      if (smsSendType) {
        this.aditWords = smsSendType.aditWords;
        this.smsSendInfo.message = smsSendType.smsWords;
        this.smsSendInfo.type = smsSendType.smsSendType;
        this.smsSendInfo.kakaoSendFlag = smsSendType.kakaoSendFlag;
        this.smsSendInfo.kakaoTemplateCode = smsSendType.kakaoTmplatCode;
        this.smsSendInfo.kakaoKind = smsSendType.smsKind;
        this.smsSendInfo.subject = smsSendType.smsSendTypeName;
        this.smsSendInfo.dsptchNo = getFormattedTelNumber(
          smsSendType.dsptchNo
            ? smsSendType.dsptchNo
            : commonCodesGetStandardInfo("dsptchNo")
        );
        this.isVisible = true;
      } else {
        this.errorToast("일치하는 SMS전송타입이 존재하지 않습니다");
        return this.closeBatchSmsSendPopup();
      }
    },
    async onSmsSendButtonClicked() {
      if (!(await this.confirm("SMS를 전송하시겠습니까?"))) {
        return;
      }

      await this.smsSend();
    },
    onTestButtonClicked() {
      this.showTestSmsSendPopup = true;
      this.$nextTick(() => {
        this.$refs.testSmsSendPopup.showPopup();
      });
    },
    async smsSend(contactTel = null) {
      if (contactTel) {
        let message = [];
        if (this.smsSendInfo.message) {
          message.push(this.smsSendInfo.message);
        }
        message.push(this.aditWords);
        if (this.bizNameFlag && this.smsSendInfo.bizName) {
          if (this.smsSendInfo.bizNameLocation === "UP") {
            message.unshift(this.smsSendInfo.bizName);
          } else {
            message.push(this.smsSendInfo.bizName);
          }
        }
        message = message.join("\n\n");

        let smsRequestList = [];
        const item = {};
        item.type = this.smsSendInfo.type;
        item.key = 0;
        item.receiverName = this.popupData.memberName;
        item.receiverNumber = contactTel;
        item.message = message;
        item.dispatchNumber = this.smsSendInfo.dsptchNo.replace(/-/gi, "");
        item.sender = "ERP";
        item.replaceDataMap = {
          name: this.popupData.memberName + "님",
          couponName: this.popupData.couponName,
          couponNo: this.popupData.couponNo,
          expirStartDate: this.popupData.expirStartDate,
          expirDate: this.popupData.expirDate,
          giveFlag: this.popupData.giveFlag ? "가능" : "불가능",
        };

        smsRequestList.push(item);
        this.showTestSmsSendPopup = false;
        await GolfErpAPI.sendSMS(smsRequestList);
      } else {
        const smsRequestList = [
          {
            couponIds: [this.popupData.couponId],
            couponKindId: this.popupData.kindId,
            aditWords: this.aditWords,
          },
        ];
        await GolfErpAPI.sendSMSByCouponPubStatus(smsRequestList);
      }
      this.infoToast(this.$t("sms.popupMessage.success"));
    },
    onInitButtonClicked() {
      this.smsSendInfo.message = this.originMessage;
    },
    onTestSmsSendPopup() {
      this.showTestSmsSendPopup = false;
    },
    async resize() {
      if (this.$refs.aditWords) {
        const container = document.querySelector(".screen-box");
        const saved = container.scrollTop;
        this.$refs.aditWords.style.height = 0;
        this.$refs.aditWords.style.height = `${this.$refs.aditWords?.scrollHeight}px`;
        await this.$nextTick();
        container.scrollTop = saved;
      }
    },
  },
};
</script>
