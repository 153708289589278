<template>
  <div>
    <ejs-dialog
      ref="couponPubStatusDetailPopupDialog"
      header="쿠폰발행 상세"
      :allowDragging="true"
      :showCloseIcon="true"
      width="500"
      height="800"
      :close="dialogClose"
      :position="{ x: 'center' }"
      :enableResize="false"
      :isModal="true"
    >
      <div class="window couponPubStatusDetail">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">쿠폰발행 상세</div>
                    </div>
                    <div class="header-right">
                      <ul class="header-button">
                        <li>
                          <erp-button
                              button-div="GET"
                              :is-icon-custom="true"
                              @click.native="onClickCouponPubDetailHistory"
                          >
                            변경이력
                          </erp-button>
                        </li>
                        <li>
                          <erp-button
                              button-div="SAVE"
                              :ignore="isPopupOpened"
                              :is-icon-custom="true"
                              :is-custom-shortcut-button="true"
                              shortcut-key="CouponPubStatusDetailPopup.shortcuts.sendSms"
                              :shortcut="{key: 'F7'}"
                              @click.native="onClickCouponPubDetailSMS"
                          >
                            SMS전송
                          </erp-button>
                        </li>
                        <li class="delete">
                          <erp-button
                              button-div="DELETE"
                              :ignore="isPopupOpened"
                              :is-shortcut-button="true"
                              v-on:click.native="onClickCouponPubDetailDelete"
                          >
                            삭제
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <article class="body-article">
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-0101">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">쿠폰정보</div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field">
                                    <div class="title">할인종류</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item text">
                                          {{
                                            getDcKindName(
                                              selectedCouponPubStatusInfo.dcKind
                                            )
                                          }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">쿠폰명</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item text">
                                          {{
                                            selectedCouponPubStatusInfo.couponName
                                          }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">쿠폰유형</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item text">
                                          {{
                                            getCouponKindName(
                                              selectedCouponPubStatusInfo.couponKind
                                            )
                                          }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">발행일자</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-date
                                              v-model="
                                                selectedCouponPubStatusInfo.pubDate
                                              "
                                              format="YYYY-MM-DD"
                                              :disabled="true"
                                            ></input-date>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">쿠폰번호</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item text">
                                          {{
                                            selectedCouponPubStatusInfo.couponNo
                                          }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">타인양도가능</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group check">
                                          <!-- 포커스 : focus -->
                                          <ul class="check">
                                            <li>
                                              <label for="giveFlagT">
                                                <input
                                                  type="radio"
                                                  id="giveFlagT"
                                                  :value="true"
                                                  v-model="
                                                    selectedCouponPubStatusInfo.giveFlag
                                                  "
                                                />
                                                <i></i>
                                                <div class="label">가능</div>
                                              </label>
                                            </li>
                                            <li>
                                              <label for="giveFlagF">
                                                <input
                                                  type="radio"
                                                  id="giveFlagF"
                                                  :value="false"
                                                  v-model="
                                                    selectedCouponPubStatusInfo.giveFlag
                                                  "
                                                />
                                                <i></i>
                                                <div class="label">불가능</div>
                                              </label>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-0102">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">발행정보</div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field memberName">
                                    <div class="title">회원명</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item text memberName">
                                          {{
                                            selectedCouponPubStatusInfo.memberName
                                          }}
                                        </li>
                                        <li class="item text">
                                          {{
                                            getMemberDivName(
                                              selectedCouponPubStatusInfo.memberDiv
                                            )
                                          }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">유효기간</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group dateRange">
                                          <div class="form">
                                            <input-date-range
                                              v-model="expirDateRange"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">핸드폰</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item text">
                                          {{
                                            getFormattedPhoneNumber(
                                              selectedCouponPubStatusInfo.contactTel
                                            )
                                          }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">SMS 전송여부</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item text">
                                          {{
                                            getSendFlagName(
                                              selectedCouponPubStatusInfo.smsSendFlag
                                            )
                                          }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-0103">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">할인방법</div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field">
                                    <div class="title required">할인방법</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group select">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              ref="dcMethod"
                                              v-model="
                                                selectedCouponPubStatusInfo.dcMethod
                                              "
                                              :dataSource="dcMethodCodes"
                                              :allowFiltering="false"
                                              :fields="dropdownListCodesOptions"
                                              cssClass="body-data-dropdown"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div
                                      :class="[
                                        'title',
                                        {
                                          required:
                                            selectedCouponPubStatusInfo.dcMethod !==
                                            'PAYMT',
                                        },
                                      ]"
                                    >
                                      금액/할인율
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-number
                                              ref="dcAmt"
                                              v-model="
                                                selectedCouponPubStatusInfo.dcAmt
                                              "
                                              :disabled="
                                                selectedCouponPubStatusInfo.dcMethod ===
                                                'PAYMT'
                                              "
                                              :allow-dot="
                                                selectedCouponPubStatusInfo.dcMethod ===
                                                'RATE'
                                              "
                                              :propMaxLength="
                                                selectedCouponPubStatusInfo.dcMethod ===
                                                'AMT'
                                                  ? 13
                                                  : 3
                                              "
                                              :max="
                                                selectedCouponPubStatusInfo.dcMethod ===
                                                'RATE'
                                                  ? 100
                                                  : undefined
                                              "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div
                                      :class="[
                                        'title',
                                        {
                                          required:
                                            selectedCouponPubStatusInfo.dcMethod ===
                                            'PAYMT',
                                        },
                                      ]"
                                    >
                                      요금코드
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group select">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              ref="paymtCode"
                                              v-model="
                                                selectedCouponPubStatusInfo.paymtCode
                                              "
                                              :enabled="
                                                selectedCouponPubStatusInfo.dcMethod ===
                                                'PAYMT'
                                              "
                                              :dataSource="paymtCodes"
                                              :allowFiltering="false"
                                              :fields="dropdownListCodesOptions"
                                              cssClass="body-data-dropdown"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-0104">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">사용제한</div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field">
                                    <div class="title required">사용매장</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group select">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              ref="storeCode"
                                              v-model="
                                                selectedCouponPubStatusInfo.storeCode
                                              "
                                              :dataSource="storeCodes"
                                              :allowFiltering="false"
                                              :fields="dropdownListCodesOptions"
                                              cssClass="body-data-dropdown"
                                            ></ejs-dropdownlist>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title required">영업구분</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group select">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              v-model="
                                                selectedCouponPubStatusInfo.bsnCode
                                              "
                                              :dataSource="bsnCodes"
                                              :allowFiltering="false"
                                              :fields="dropdownListCodesOptions"
                                              cssClass="body-data-dropdown"
                                            ></ejs-dropdownlist>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title required">
                                      사용가능시간
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group time">
                                          <div class="form">
                                            <input-time
                                              v-model="
                                                selectedCouponPubStatusInfo.useStartTime
                                              "
                                              :format="'HH:mm'"
                                            />
                                          </div>
                                        </li>
                                        <li class="item text">~</li>
                                        <li class="item form-group time">
                                          <div class="form">
                                            <input-time
                                              v-model="
                                                selectedCouponPubStatusInfo.useEndTime
                                              "
                                              :format="'HH:mm'"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">중복할인가능</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group check">
                                          <!-- 포커스 : focus -->
                                          <ul class="check">
                                            <li>
                                              <label>
                                                <input
                                                  type="checkbox"
                                                  v-model="
                                                    selectedCouponPubStatusInfo.dplctDcFlag
                                                  "
                                                />
                                                <i></i>
                                              </label>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-0105">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">회입정보</div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field">
                                    <div class="title">회입여부</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group check">
                                          <!-- 포커스 : focus -->
                                          <ul class="check">
                                            <li>
                                              <label>
                                                <input
                                                  type="checkbox"
                                                  v-model="
                                                    selectedCouponPubStatusInfo.returnFlag
                                                  "
                                                  @change="onReturnFlagChange"
                                                />
                                                <i></i>
                                              </label>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li
                                    class="field"
                                    v-if="
                                      selectedCouponPubStatusInfo.returnFlag
                                    "
                                  >
                                    <div class="title required">회입매장</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group select">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              ref="returnStore"
                                              v-model="
                                                selectedCouponPubStatusInfo.returnStore
                                              "
                                              :dataSource="storeCodes"
                                              :allowFiltering="false"
                                              :fields="dropdownListCodesOptions"
                                              cssClass="body-data-dropdown"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li
                                    class="field"
                                    v-if="
                                      selectedCouponPubStatusInfo.returnFlag
                                    "
                                  >
                                    <div class="title required">회입일자</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-date
                                              v-model="
                                                selectedCouponPubStatusInfo.returnDate
                                              "
                                              format="YYYY-MM-DD"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li
                                    class="field visitorInfo"
                                    v-if="
                                      selectedCouponPubStatusInfo.returnFlag
                                    "
                                  >
                                    <div class="title required">
                                      내장객 정보
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group input">
                                          <div class="form">
                                            <input-text
                                              v-model="
                                                selectedCouponPubStatusInfo.visitName
                                              "
                                            />
                                          </div>
                                        </li>
                                        <li class="item form-group time">
                                          <div class="form">
                                            <input-time
                                              v-model="
                                                selectedCouponPubStatusInfo.startTime
                                              "
                                              format="HH:mm"
                                            />
                                          </div>
                                        </li>
                                        <li class="item form-group select">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              ref="startCourse"
                                              v-model="
                                                selectedCouponPubStatusInfo.startCourse
                                              "
                                              :dataSource="startCourseCodes"
                                              :allowFiltering="false"
                                              :fields="dropdownListCodesOptions"
                                              cssClass="body-data-dropdown"
                                            ></ejs-dropdownlist>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-0106">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">등록자/일시</div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field">
                                    <div class="title">등록자/일시</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item text userName">
                                          {{
                                            selectedCouponPubStatusInfo.userName
                                          }}
                                        </li>
                                        <li class="item text">
                                          {{
                                            getTimeFormat(
                                              selectedCouponPubStatusInfo.insertDt
                                            )
                                          }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                    </article>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :ignore="isPopupOpened"
                  :is-shortcut-button="true"
                  :is-key-color="true"
                v-on:click.native="onClickCouponPubDetailSave"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  v-on:click.native="dialogClose"> 닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <history-popup
      ref="historyPopup"
      v-if="isHistoryPopupOpen"
      @popupClosed="historyPopupClose"
    />
    <batch-sms-coupon-send-popup
      ref="batchSmsSendPopup"
      v-if="isBatchSmsSendPopupOpen"
      @popupClosed="batchSmsSendPopupClosed"
    ></batch-sms-coupon-send-popup>
  </div>
</template>

<script>
import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import InputNumber from "@/components/common/InputNumber";
import InputTime from "@/components/common/datetime/InputTime";
import { validateFormRefs } from "@/utils/formUtil";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import commonMixin from "@/views/layout/mixin/commonMixin";
import Vue from "vue";
import AsyncComputed from "vue-async-computed";
import GolfERPService from "@/service/GolfERPService";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import moment from "moment";
import InputDate from "@/components/common/datetime/InputDate";
import HistoryPopup from "@/views/common/HistoryPopup";
import BatchSmsCouponSendPopup from "@/views/common/BatchSmsCouponSendPopup";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { getFormattedPhoneNumber } from "@/utils/string";
import ErpButton from "@/components/button/ErpButton.vue";

Vue.use(AsyncComputed);

const couponInfoInitData = Object.freeze({
  dcKind: null,
  giveFlag: true,
  dcMethod: null,
  dcAmt: null,
  paymtCode: null,
  storeCode: null,
  bsnCode: "%",
  startTime: null,
  endTime: null,
  dplctDcFlag: true,
  smsSendFlag: true,
  smsAditWords: null,
  returnStore: null,
});

export default {
  name: "CouponPubStatusDetailPopup",
  components: {
    InputText,
    InputDate,
    InputDateRange,
    InputTime,
    InputNumber,
    HistoryPopup,
    BatchSmsCouponSendPopup,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  data() {
    return {
      dcKindCodes: commonCodesGetCommonCode("DC_KIND"),
      dcMethodCodes: commonCodesGetCommonCode("DC_METHOD"),
      couponKindCodes: commonCodesGetCommonCode("COUPON_KIND"),
      memberDivCodes: commonCodesGetCommonCode("MEMBER_DIV", false),
      paymtCodes: commonCodesGetCommonCode("PAYMT_CODE"),
      dropdownListCodesOptions: { text: "comName", value: "comCode" },
      dropdownCouponKindCodesOptions: { text: "couponName", value: "kindId" },
      selectedCouponPubStatusInfo: JSON.parse(
        JSON.stringify(couponInfoInitData)
      ),
      expirDateRange: {
        from: moment().format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
      },
      selectedCouponId: null,
      kindId: null,
      isHistoryPopupOpen: false,
      isBatchSmsSendPopupOpen: false,
      oldCouponInfo: null,
      couponKindList: [],
      startCourseCodes: [],
      validateRefList: {
        couponKind: { required: true },
      },
    };
  },
  watch: {
    selectedCouponPubStatusInfo: {
      deep: true,
      handler(data) {
        if (data.dcMethod === "PAYMT") {
          data.dcAmt = 0;
        } else {
          data.paymtCode = null;
        }
        if (this.oldCouponInfo && this.oldCouponInfo.dcMethod) {
          if (this.oldCouponInfo.dcMethod !== data.dcMethod) {
            data.dcAmt = 0;
          }
        }
        this.oldCouponInfo = JSON.parse(JSON.stringify(data));
      },
    },
  },
  asyncComputed: {
    async storeCodes() {
      const response = await GolfERPService.fetchStores(true);
      return commonCodesGetCommonCode("STORE_CODE").filter((data) =>
        response.find((rData) => rData.code === data.comCode)
      );
    },
  },
  computed: {
    isPopupOpened() {
      return this.isHistoryPopupOpen || this.isBatchSmsSendPopupOpen;
    },
    bsnCodes() {
      const result = commonCodesGetCommonCode("BSN_CODE").filter(
        (data) => data.comCode !== "CLOSE"
      );
      result.unshift({ comCode: "%", comName: "전체" });
      return result;
    },
  },
  created() {
    this.startCourseCodes = commonCodesGetCommonCode("COURSE_CODE");
  },
  methods: {
    validateFormRefs,
    getFormattedPhoneNumber,
    getCouponKindName(data) {
      if (!data) {
        return null;
      }
      return this.couponKindCodes.find((dcData) => dcData.comCode === data)
        .comName;
    },
    getSendFlagName(data) {
      if (data) {
        return "전송";
      } else {
        return "미전송";
      }
    },
    getTimeFormat(data) {
      if (!data) {
        return null;
      }
      return moment(data).format("YYYY-MM-DD");
    },
    getMemberDivName(data) {
      if (!data) {
        return null;
      }
      return this.memberDivCodes.find((dcData) => dcData.comCode === data)
        ?.comName;
    },
    getDcKindName(data) {
      if (!data) {
        return null;
      }
      return this.dcKindCodes.find((dcData) => dcData.comCode === data)
        ?.comName;
    },
    async initCouponPubStatusDetailData() {
      this.selectedCouponPubStatusInfo = JSON.parse(
        JSON.stringify(couponInfoInitData)
      );
      await GolfErpAPI.fetchCouponPubStatusDetail(this.selectedCouponId).then(
        (data) => {
          this.selectedCouponPubStatusInfo = data;
          this.expirDateRange.from = data.startDate;
          this.expirDateRange.to = data.expirDate;
        }
      );
    },
    showPopup(couponId) {
      this.selectedCouponId = couponId;
      this.initCouponPubStatusDetailData();
      this.$refs.couponPubStatusDetailPopupDialog.show();
    },
    dialogClose(isRefresh) {
      if (isRefresh === true) {
        this.$emit("popupClosed", true);
      } else {
        this.$emit("popupClosed", null);
      }
    },
    onClickCouponPubDetailSave() {
      if (this.selectedCouponPubStatusInfo.returnFlag) {
        if (this.selectedCouponPubStatusInfo.returnStore === "") {
          return this.errorToast(
            this.$t("main.validationMessage.requiredMessage", ["회입매장"])
          );
        }
        if (!this.selectedCouponPubStatusInfo.returnDate) {
          return this.errorToast(
            this.$t("main.validationMessage.requiredMessage", ["회입일자"])
          );
        }
        if (
          this.selectedCouponPubStatusInfo.visitName === "" ||
          !this.selectedCouponPubStatusInfo.startTime ||
          this.selectedCouponPubStatusInfo.startCourse === ""
        ) {
          return this.errorToast(
            this.$t("main.validationMessage.requiredMessage", ["내장객 정보"])
          );
        }
      } else {
        this.selectedCouponPubStatusInfo.returnStore = "";
        this.selectedCouponPubStatusInfo.returnDate = null;
        this.selectedCouponPubStatusInfo.visitName = "";
        this.selectedCouponPubStatusInfo.startTime = null;
        this.selectedCouponPubStatusInfo.startCourse = "";
      }

      let saveBody = JSON.parse(
        JSON.stringify(this.selectedCouponPubStatusInfo)
      );

      saveBody.startDate = this.expirDateRange.from;
      saveBody.expirDate = this.expirDateRange.to;
      saveBody.dcAmt = saveBody.dcMethod === "PAYMT" ? 0 : saveBody.dcAmt;
      saveBody.paymtCode =
        saveBody.dcMethod === "PAYMT" ? saveBody.paymtCode : null;

      GolfErpAPI.putCouponPubStatusDetail(saveBody)
        .then(() => {
          this.infoToast(this.$t("main.popupMessage.saved"));
          this.dialogClose(true);
        })
        .catch((err) => {
          console.log("err.===>", err);
        });
    },
    async onClickCouponPubDetailDelete() {
      if (await this.confirm("정말 삭제하시겠습니까?")) {
        GolfErpAPI.deleteCouponPubStatus({
          data: [this.selectedCouponId],
        }).then(() => {
          this.infoToast(this.$t("main.popupMessage.deleted"));
          this.dialogClose(true);
        });
      }
    },
    onClickCouponPubDetailSMS() {
      if (!this.selectedCouponPubStatusInfo.contactTel) {
        this.errorToast("전화번호가 없습니다");
        return;
      }

      this.isBatchSmsSendPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.batchSmsSendPopup.showBatchSmsSendPopup({
          couponId: this.selectedCouponPubStatusInfo.couponId,
          couponName: this.selectedCouponPubStatusInfo.couponName,
          couponNo: this.selectedCouponPubStatusInfo.couponNo,
          expirStartDate: this.expirDateRange.from,
          expirDate: this.expirDateRange.to,
          giveFlag: this.selectedCouponPubStatusInfo.giveFlag,
          kindId: this.selectedCouponPubStatusInfo.kindId,
          memberName: this.selectedCouponPubStatusInfo.memberName,
          smsMessage: this.selectedCouponPubStatusInfo.smsAditWords,
          imagePath: this.selectedCouponPubStatusInfo.imagePath,
        });
      });
    },
    onClickCouponPubDetailHistory() {
      GolfErpAPI.fetchCouponPubStatusHistory(this.selectedCouponId).then(
        (res) => {
          this.isHistoryPopupOpen = true;
          this.$nextTick(() => {
            this.$refs.historyPopup.onShowHistoryPopup(res);
          });
        }
      );
    },
    onReturnFlagChange(args) {
      if (args.target.checked) {
        this.selectedCouponPubStatusInfo.returnStore = "FRONT";
        this.selectedCouponPubStatusInfo.returnDate = moment().format(
          "YYYY-MM-DD"
        );
        this.selectedCouponPubStatusInfo.startCourse = "1";
      } else {
        this.selectedCouponPubStatusInfo.returnStore = "";
        this.selectedCouponPubStatusInfo.returnDate = null;
        this.selectedCouponPubStatusInfo.visitName = "";
        this.selectedCouponPubStatusInfo.startTime = null;
        this.selectedCouponPubStatusInfo.startCourse = "";
      }
    },
    historyPopupClose: function () {
      this.isHistoryPopupOpen = false; // 닫히면 DOM에서 없애버림. 새로 띄울 때 초기화시키기
    },
    batchSmsSendPopupClosed() {
      this.isBatchSmsSendPopupOpen = false;
    },
  },
};
</script>

<style scoped></style>
